import React, { useEffect, useState } from "react"
import Loader from "../components/Loader"
import ContactInfo from "../components/ContactInfo"
import ContactForm from "../components/ContactForm"
import { Container, Row, Col, Table, Button, Modal, Badge } from "react-bootstrap"
// import '../css/styles.css'
// import playIcon from '../images/play-button-32x32.png'
import { NavBarButtons } from "../components/nav-buttons/nav-bar-buttons"
let today = new Date()
const dd = String(today.getDate()).padStart(2, "0")
const mm = String(today.getMonth() + 1).padStart(2, "0") //January is 0!
const yyyy = today.getFullYear()
today = yyyy + "-" + mm + "-" + dd
// const thisYearMonth = yyyy + '-' + mm
// const thisYear = yyyy
// time:
// const time = new Date().toLocaleTimeString('sv-SE', { timeZone: 'Europe/Stockholm' })
// today.toLocaleTimeString('sv-SE', { timeZone: 'Europe/Stockholm' })
// console.log('server date is:', today, time)
//-----------------------------------------------------------------------------------------------------
const urlApiRw = process.env.REACT_APP_API_URL_RW

const BatRouters = () => {
  const [units, setUnits] = useState([])
  const [showForm, setShowForm] = useState("false")
  const handleToggle = () => setShowForm(!showForm)
  const [dataTime, setDataTime] = useState("")
  // count all units:
  const totalUnits = units.length
  // count all units tagged as 'online':
  const totalUnitsOnline = units.filter((unit) => unit.status === 1).length
  // count all units tagged as 'offline:
  const totalUnitsOffline = units.filter((unit) => unit.status === 0).length
  // count all units tagged as 'PENDING':
  const unitsPending = units.filter((unit) => unit.status === 2).length
  // For Modal window:
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  useEffect(() => {
    fetch(urlApiRw, {
      headers: {
        'x-api-key': process.env.REACT_APP_API_KEY, // Make sure this matches your server's API_KEY
        "Content-Type": "application/json"
      },
    })
      .then((res) => res.json())
      .then((data) => {
        // extract timestamp from fetched endpoint data:
        const fetchedDataTimestamp = data.slice(-1)
        const dataTimeStamp = fetchedDataTimestamp[0].timestamp
        setDataTime(dataTimeStamp)
        data.pop() // remove last item (timestamp) from array
        setUnits(data) // store data in state
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  return (
    <Container>
      <Row>
        <Col>
          <ContactInfo />
        </Col>
      </Row>
      <Row>
        <Col>
          <NavBarButtons />
          <h3 className="text-white">Network Routers</h3>
          <p className="text-note-this" style={{ fontSize: "0.8rem" }}>
            Realtime data - from last system heartbeat.
          </p>
        </Col>

        {/* Page switch */}
        {/* <Col>
          <NavPage />
        </Col> */}

        <Col>
          <Button variant="dark" onClick={handleShow} size="sm" style={{ fontSize: "0.8rem", backgroundColor: "black", color: "white", borderColor: "white" }}>
            Technical explanations here
          </Button>
          <Badge pill bg="warning" text="dark" style={{ marginLeft: "5px" }}>
            NEW
          </Badge>

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Technical explanations</Modal.Title>
            </Modal.Header>

            <Modal.Body className="bg-dark text-white small" border="secondary">
              <span className="fw-bold">RSSI (dBm) 📡</span>
              <br />
              <h4 className="small">
                {" "}
                "Received Signal Strength Indicator" is a measurement of the power present in a received radio signal for LTE. -70 dBm and higher values (closer
                to 0) usually equate to the modem being in an excellent coverage area. <br /> <br />
                The RSSI is a relative value. Its measurement depends on the receiving device. The dashboard indicates active RSSI readings lower or equal to
                -76 dBm, to be a warning.
              </h4>
            </Modal.Body>
            <Modal.Body className="bg-dark text-white small" border="secondary">
              <span className="fw-bold">Temperature 🌡️</span>
              <br />
              <h4 className="small">
                {" "}
                The temperature of the system. The temperature is measured in Celsius (°C) and by the system's internal module. These industrial routers are
                designed to operate in a wide range of temperatures from -40° to +75° Celsius. <br /> <br />
                The dashboard indicates active temperature readings over or equal to 50°C, to be a warning as a lot of other technical equipment on site (in the
                consumer range) cant handle higher temperatures properly.
              </h4>
            </Modal.Body>
            <Modal.Body className="bg-dark text-white small" border="secondary">
              <span className="fw-bold">Active systems ⚙️</span>
              <br />
              <h4 className="small">
                {" "}
                Only data from active systems are highlighted in the dashboard. The system is considered active if it has sent status data within the current
                month.
              </h4>
              <br />
            </Modal.Body>
          </Modal>
        </Col>

        <Col>
          <Button onClick={handleToggle} variant="outline-warning" size="sm" className="float-end mb-4">
            Contact
          </Button>
        </Col>
      </Row>
      {/* Contact Form: */}
      <Row className={showForm ? "collapse" : "collapse.show"}>
        <Col>
          <ContactForm />
        </Col>
      </Row>

      <Row>
        <Col>
          <Table variant="dark" size="sm">
            <thead>
              <tr>
                <th style={{ backgroundColor: "transparent" }}>
                  <h6 className="text-white">
                    Systems total{" "}
                    <span className="badge rounded-pill bg-dark" style={{ width: "auto", fontSize: 14 }}>
                      {totalUnits}
                    </span>
                  </h6>
                </th>
                <th style={{ backgroundColor: "transparent" }}>
                  <h6 className="text-white">
                    Status: Up{" "}
                    <span className="badge rounded-pill bg-online" style={{ width: "auto", fontSize: 14 }}>
                      {totalUnitsOnline}
                    </span>
                  </h6>
                </th>
                <th style={{ backgroundColor: "transparent" }}>
                  <h6 className="text-white">
                    Status: Down{" "}
                    <span className="badge rounded-pill bg-no-internet" style={{ width: "auto", fontSize: 14 }}>
                      {totalUnitsOffline}
                    </span>
                  </h6>
                </th>
                <th style={{ backgroundColor: "transparent" }}>
                  <h6 className="text-white">
                    Status: Pending{" "}
                    <span className="badge rounded-pill bg-pending" style={{ width: "auto", fontSize: 14 }}>
                      {unitsPending}
                    </span>
                  </h6>
                </th>
              </tr>
            </thead>
          </Table>
          <Table striped hover variant="dark" size="sm">
            <thead>
              {units.length > 0 ? (
                <tr>
                  <th>
                    <h6>
                      <span className="small fw-bold text-white">Name</span>
                    </h6>
                  </th>

                  <th>
                    <h6>
                      <span className="small fw-bold text-white">
                        Last heartbeat: <span style={{ fontWeight: "lighter" }}>(SE:+1 hr)</span>
                      </span>
                    </h6>
                  </th>

                  <th>
                    <h6>
                      <span className="small fw-bold text-white">Protocol</span>
                    </h6>
                  </th>

                  <th>
                    <h6>
                      <span className="small fw-bold text-white">RSSI</span>
                    </h6>
                  </th>

                  <th>
                    <h6>
                      <span className="small fw-bold text-white">Sys temp</span>
                    </h6>
                  </th>

                  <th>
                    <h6>
                      <span className="small fw-bold text-white">ID code</span>
                    </h6>
                  </th>

                  <th>
                    <h6>
                      <span className="small fw-bold text-white">Status</span>
                    </h6>
                  </th>
                </tr>
              ) : (
                <tr>
                  <td>Data - fetching</td>
                </tr>
              )}
            </thead>

            <tbody className="small">
              {units.length > 0 ? (
                units.map((record) => (
                  <tr key={record.id}>
                    {/* Project name/store: */}
                    <td>
                      {/* <span
                        className='badge rounded-pill bg-dark'
                      >
                        {record.name.length > 70 ? record.name.slice(0, 50) + '..' : record.name}{' '}
                      </span> */}
                      <span
                        className={
                          record.status === 0 ? "badge rounded-pill text-danger" : record.status === 1 ? "badge rounded-pill text-online" : "badge rounded-pill"
                        }
                      >
                        {record.name.length > 70 ? record.name.slice(0, 50) + ".." : record.name}{" "}
                      </span>
                    </td>
                    {/* Updated at: */}
                    <td>
                      {/* <span className='badge rounded-pill bg-dark'>{dataTime}</span> */}
                      <span
                        className={
                          record.last_update_at.slice(0, 4) === dataTime.slice(0, 4)
                            ? "badge rounded-pill bg-dark"
                            : "badge rounded-pill bg-dark text-secondary"
                        }
                      >
                        {record.last_update_at.slice(0, 10)}
                      </span>
                      <span
                        className={
                          record.last_update_at.slice(0, 4) === dataTime.slice(0, 4)
                            ? "badge rounded-pill bg-dark"
                            : "badge rounded-pill bg-dark text-secondary"
                        }
                      >
                        {record.last_update_at.slice(11, 16)}
                      </span>
                    </td>
                    {/* Connection/protocol type */}
                    <td>
                      <span
                        className={
                          record.last_update_at.slice(0, 4) === dataTime.slice(0, 4)
                            ? "badge rounded-pill bg-dark"
                            : "badge rounded-pill bg-dark text-secondary"
                        }
                      >
                        {record.connection_type}
                      </span>
                    </td>
                    {/* Signal Quality */}
                    {/* HERE: null check added before .toString() as app crashes on null/undefined */}
                    <td>
                      <span
                        className={
                          (record.signal == null ? "" : record.signal.toString()) === "0" && record.last_update_at.slice(0, 7) === dataTime.slice(0, 7)
                            ? "badge rounded-pill bg-danger text-warning"
                            : (record.signal == null ? "" : record.signal.toString()) >= "-76" && record.last_update_at.slice(0, 7) === dataTime.slice(0, 7)
                            ? "badge rounded-pill bg-dark text-warning"
                            : record.last_update_at.slice(0, 4) !== dataTime.slice(0, 4)
                            ? "badge rounded-pill bg-dark text-secondary"
                            : "badge rounded-pill bg-dark"
                        }
                      >
                        {(record.signal == null ? "" : record.signal.toString()) === "0" && record.last_update_at.slice(0, 7) === dataTime.slice(0, 7)
                          ? "no signal (temp. offline)"
                          : (record.signal == null ? "" : record.signal.toString()) >= "-76" && record.last_update_at.slice(0, 7) === dataTime.slice(0, 7)
                          ? record.signal + " dBm !"
                          : record.signal + " dBm"}
                      </span>
                    </td>
                    {/* Temperature: */}
                    {/* HERE: null check added before .toString() as app crashes on null/undefined */}
                    <td>
                      <span
                        className={
                          (record.temperature == null ? "" : record.temperature.toString()).slice(0, 2) >= "50" &&
                          record.last_update_at.slice(0, 7) === dataTime.slice(0, 7)
                            ? "badge rounded-pill bg-dark text-warning"
                            : record.last_update_at.slice(0, 4) !== dataTime.slice(0, 4)
                            ? "badge rounded-pill bg-dark text-secondary"
                            : "badge rounded-pill bg-dark"
                        }
                      >
                        {(record.temperature == null ? "" : record.temperature.toString()).slice(0, 2) >= "50" &&
                        record.last_update_at.slice(0, 7) === dataTime.slice(0, 7)
                          ? (record.temperature == null ? "" : record.temperature.toString()).slice(0, 2) + " °C !"
                          : // : record.last_update_at.slice(0, 4) < dataTime.slice(0, 4)
                            // ? record.last_update_at.slice(0, 4) + ' :: ' + dataTime.slice(0, 4)
                            (record.temperature == null ? "" : record.temperature.toString()).slice(0, 2) + " °C"}
                      </span>
                    </td>
                    {/* Router ID: */}
                    <td>
                      <span className="badge rounded-pill bg-dark">VSL{record.serial.slice(5)}RBL</span>
                    </td>
                    {/* Router Status: */}
                    <td>
                      <span
                        className={
                          record.status === 0
                            ? "badge rounded-pill bg-no-internet"
                            : record.status === 1
                            ? "badge rounded-pill bg-online"
                            : "badge rounded-pill bg-pending "
                        }
                      >
                        {record.status === 1 ? "Up" : record.status === 0 ? "Down" : "pending"}
                      </span>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5">
                    <Loader />
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  )
}

export default BatRouters
